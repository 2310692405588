import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Form/TradeExecutionTypeButtons.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Form/OrderTypeSelect.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/Form/OrderInputs.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Trading/Form/Debug.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Trading/OrderDetails/Index.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Trading/Form/OrderSubmit.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Modals/PriceDeviation.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, normalizeProps as _normalizeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col gap-6"
};
import { BigNumberInWei, Status, BigNumberInBase } from "@injectivelabs/utils";
import { TradeExecutionType } from "@injectivelabs/ts-types";
import {
  ZERO_IN_BASE,
  SpotOrderSide
} from "@injectivelabs/sdk-ui-ts";
import {
  Modal,
  TradeField,
  OrderAttemptStatus
} from "@/types";
import { amplitudeTracker } from "@/app/providers/AmplitudeTracker";
import {
  DEBUG_CALCULATION,
  TRADE_FORM_PRICE_ROUNDING_MODE
} from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Form",
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const bankStore = useBankStore();
    const spotStore = useSpotStore();
    const modalStore = useModalStore();
    const { t } = useLang();
    const { $onError } = useNuxtApp();
    const { success } = useNotifications();
    const {
      values,
      setFieldValue,
      errors: formErrors,
      resetForm: resetFormValues
    } = useForm();
    const defaultStep = "1";
    const isBaseAmount = ref(true);
    const status = ref(new Status());
    const formValues = computed(() => values);
    const {
      baseAmount,
      limitPrice,
      hasBaseAmount,
      tradingTypeLimit,
      tradingTypeMarket
    } = useSpotFormFormatter(formValues);
    const { makerFeeRate, takerFeeRate } = useTradeFee(computed(() => props.market));
    const amountStep = computed(
      () => props.market ? new BigNumberInBase(1).shiftedBy(props.market.quantityTensMultiplier).toFixed() : defaultStep
    );
    const priceStep = computed(
      () => props.market ? new BigNumberInBase(1).shiftedBy(-props.market.priceDecimals).toFixed() : defaultStep
    );
    const isBuy = computed(
      () => formValues.value[TradeField.OrderType] === SpotOrderSide.Buy
    );
    const orderTypeToSubmit = computed(() => {
      switch (true) {
        case (formValues.value[TradeField.PostOnly] && isBuy.value): {
          return SpotOrderSide.BuyPO;
        }
        case isBuy.value: {
          return SpotOrderSide.Buy;
        }
        case (formValues.value[TradeField.PostOnly] && !isBuy.value): {
          return SpotOrderSide.SellPO;
        }
        case !isBuy.value: {
          return SpotOrderSide.Sell;
        }
        default: {
          return SpotOrderSide.Buy;
        }
      }
    });
    const baseAvailableBalance = computed(() => {
      const balance = bankStore.balanceMap[props.market.baseDenom] || "0";
      const baseAvailableBalance2 = new BigNumberInWei(balance).toBase(
        props.market.baseToken.decimals
      );
      return baseAvailableBalance2;
    });
    const quoteAvailableBalance = computed(() => {
      const balance = bankStore.balanceMap[props.market.quoteDenom] || "0";
      const quoteAvailableBalance2 = new BigNumberInWei(balance).toBase(
        props.market.quoteToken.decimals
      );
      if (quoteAvailableBalance2.isNaN()) {
        return ZERO_IN_BASE;
      }
      return quoteAvailableBalance2;
    });
    const feeRate = computed(() => {
      if (formValues.value[TradeField.PostOnly] && !tradingTypeMarket.value) {
        return makerFeeRate.value;
      }
      return takerFeeRate.value;
    });
    const hasExecutionPrice = computed(() => executionPrice.value.gt("0"));
    const { lastTradedPrice } = useSpotLastPrice(computed(() => props.market));
    const {
      slippage,
      maxAmountOnOrderbook,
      updateAmountFromBase,
      worstPriceWithSlippage
    } = useSpotPrice({
      formValues,
      isBaseAmount,
      market: computed(() => props.market)
    });
    const executionPrice = computed(() => {
      return tradingTypeMarket.value ? worstPriceWithSlippage.value : limitPrice.value;
    });
    const notionalValue = computed(() => {
      if (!hasExecutionPrice.value || !hasBaseAmount.value) {
        return ZERO_IN_BASE;
      }
      const price = tradingTypeMarket.value ? worstPriceWithSlippage.value : limitPrice.value;
      return price.times(formValues.value[TradeField.BaseAmount]);
    });
    const fees = computed(() => {
      if (notionalValue.value.isNaN()) {
        return ZERO_IN_BASE;
      }
      return notionalValue.value.times(feeRate.value);
    });
    const notionalWithFees = computed(() => {
      if (notionalValue.value.isNaN() || notionalValue.value.lte(0)) {
        return ZERO_IN_BASE;
      }
      return isBuy.value ? fees.value.plus(notionalValue.value) : notionalValue.value.minus(fees.value);
    });
    const { availableBalanceError, highDeviation, maxOrdersError } = useSpotError({
      isBuy,
      formValues,
      executionPrice,
      notionalWithFees,
      quoteAvailableBalance,
      market: computed(() => props.market)
    });
    watch(
      lastTradedPrice,
      (newPrice) => {
        const hasNoInputPrice = !hasExecutionPrice.value || executionPrice.value.lte(0);
        const hasLatestLastTradedPrice = newPrice.gt("0");
        if (hasNoInputPrice && hasLatestLastTradedPrice) {
          const formattedPrice = newPrice.toFixed(
            props.market.priceDecimals,
            TRADE_FORM_PRICE_ROUNDING_MODE
          );
          updateFormValue({ field: TradeField.LimitPrice, value: formattedPrice });
        }
      },
      { immediate: true }
    );
    watch(executionPrice, () => {
      if (maxAmountOnOrderbook.value.totalQuantity.eq(0)) {
        return;
      }
      updateAmount({ isBaseAmount: isBaseAmount.value });
    });
    function updateFormValue({ field, value }) {
      setFieldValue(field, value);
    }
    function updateAmount({
      amount,
      isBaseAmount: isBaseAmountUpdate
    }) {
      isBaseAmount.value = isBaseAmountUpdate;
      const amountToUpdate = updateAmountFromBase({
        amount,
        isBaseAmount: isBaseAmountUpdate
      });
      if (amountToUpdate) {
        updateFormValue({
          field: isBaseAmountUpdate ? TradeField.QuoteAmount : TradeField.BaseAmount,
          value: amountToUpdate
        });
      }
    }
    function setDefaultFormValues() {
      updateFormValue({ field: TradeField.BaseAmount, value: amountStep.value });
      updateFormValue({
        field: TradeField.LimitPrice,
        value: lastTradedPrice.value.toFixed(
          props.market.priceDecimals,
          TRADE_FORM_PRICE_ROUNDING_MODE
        )
      });
    }
    function resetForm() {
      resetFormValues();
      setDefaultFormValues();
    }
    function submitLimitOrder() {
      status.value.setLoading();
      spotStore.submitLimitOrder({
        market: props.market,
        price: limitPrice.value,
        quantity: baseAmount.value,
        orderType: orderTypeToSubmit.value
      }).then(() => {
        handleAttemptPlaceOrderTrack();
        success({ title: t("trade.order_placed") });
        resetForm();
      }).catch((e) => {
        handleAttemptPlaceOrderTrack(e);
        $onError(e);
      }).finally(() => {
        status.value.setIdle();
      });
    }
    function submitMarketOrder() {
      status.value.setLoading();
      spotStore.submitMarketOrder({
        isBuy: isBuy.value,
        market: props.market,
        quantity: baseAmount.value,
        price: worstPriceWithSlippage.value
      }).then(() => {
        handleAttemptPlaceOrderTrack();
        success({ title: t("trade.order_placed") });
        resetForm();
      }).catch((e) => {
        handleAttemptPlaceOrderTrack(e);
        $onError(e);
      }).finally(() => {
        status.value.setIdle();
      });
    }
    function handleRequestSubmit() {
      if (highDeviation.value) {
        return modalStore.openModal({
          type: Modal.PriceDeviation
        });
      }
      return handleSubmit();
    }
    function handleSubmit() {
      switch (formValues.value[TradeField.TradingType]) {
        case TradeExecutionType.LimitFill:
          return submitLimitOrder();
        case TradeExecutionType.Market:
          return submitMarketOrder();
      }
    }
    function handleAttemptPlaceOrderTrack(errorMessage) {
      const slippageTolerance = tradingTypeMarket.value ? formValues.value[TradeField.SlippageTolerance] : "";
      const postOnly = tradingTypeLimit.value && formValues.value[TradeField.PostOnly];
      const status2 = errorMessage ? OrderAttemptStatus.Error : OrderAttemptStatus.Success;
      amplitudeTracker.submitAttemptPlaceOrderTrackEvent({
        status: status2,
        postOnly,
        slippageTolerance,
        error: errorMessage,
        market: props.market.slug,
        marketType: props.market.subType,
        amount: formValues.value[TradeField.BaseAmount],
        orderType: formValues.value[TradeField.OrderType],
        limitPrice: formValues.value[TradeField.LimitPrice],
        tradingType: formValues.value[TradeField.TradingType]
      });
    }
    return (_ctx, _cache) => {
      const _component_PartialsTradingFormTradeExecutionTypeButtons = __nuxt_component_0;
      const _component_PartialsTradingFormOrderTypeSelect = __nuxt_component_1;
      const _component_PartialsTradingFormOrderInputs = __nuxt_component_2;
      const _component_PartialsTradingFormDebug = __nuxt_component_3;
      const _component_PartialsTradingOrderDetails = __nuxt_component_4;
      const _component_PartialsTradingFormOrderSubmit = __nuxt_component_5;
      const _component_ModalsPriceDeviation = __nuxt_component_6;
      return _unref(lastTradedPrice) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PartialsTradingFormTradeExecutionTypeButtons, { "onForm:reset": setDefaultFormValues }),
        _createVNode(_component_PartialsTradingFormOrderTypeSelect, _mergeProps({ market: props.market }, { "onUpdate:formValue": updateFormValue }), null, 16),
        _createVNode(_component_PartialsTradingFormOrderInputs, _mergeProps({
          fees: _unref(fees),
          isBuy: _unref(isBuy),
          market: __props.market,
          feeRate: _unref(feeRate),
          priceStep: _unref(priceStep),
          amountStep: _unref(amountStep),
          isBaseAmount: _unref(isBaseAmount),
          executionPrice: _unref(executionPrice),
          lastTradedPrice: _unref(lastTradedPrice),
          baseAvailableBalance: _unref(baseAvailableBalance),
          maxAmountOnOrderbook: _unref(maxAmountOnOrderbook),
          availableBalanceError: _unref(availableBalanceError),
          quoteAvailableBalance: _unref(quoteAvailableBalance),
          worstPriceWithSlippage: _unref(worstPriceWithSlippage)
        }, { "onUpdate:amount": updateAmount }), null, 16),
        _unref(DEBUG_CALCULATION) ? (_openBlock(), _createBlock(_component_PartialsTradingFormDebug, _normalizeProps(_mergeProps({ key: 0 }, {
          fees: _unref(fees),
          isBuy: _unref(isBuy),
          market: __props.market,
          feeRate: _unref(feeRate),
          isSpot: true,
          isBaseAmount: _unref(isBaseAmount),
          notionalValue: _unref(notionalValue),
          notionalWithFees: _unref(notionalWithFees)
        })), null, 16)) : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_component_PartialsTradingOrderDetails, _mergeProps({
          key: _unref(formValues)[_unref(TradeField).TradingType]
        }, {
          fees: _unref(fees),
          isBuy: _unref(isBuy),
          market: __props.market,
          feeRate: _unref(feeRate),
          slippage: _unref(slippage),
          notionalValue: _unref(notionalValue),
          executionPrice: _unref(executionPrice),
          notionalWithFees: _unref(notionalWithFees)
        }), null, 16)),
        _createVNode(_component_PartialsTradingFormOrderSubmit, _mergeProps({
          isBuy: _unref(isBuy),
          status: _unref(status),
          market: __props.market,
          formErrors: _unref(formErrors),
          formValues: _unref(formValues),
          hasBaseAmount: _unref(hasBaseAmount),
          highDeviation: _unref(highDeviation),
          executionPrice: _unref(executionPrice),
          maxOrdersError: _unref(maxOrdersError),
          availableBalanceError: _unref(availableBalanceError)
        }, { "onSubmit:request": handleRequestSubmit }), null, 16),
        _createVNode(_component_ModalsPriceDeviation, { "onOrder:confirmed": handleSubmit })
      ])) : _createCommentVNode("", true);
    };
  }
});
