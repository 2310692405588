"use strict";
import { BigNumberInBase, BigNumberInWei } from "@injectivelabs/utils";
import {
  ZERO_IN_BASE
} from "@injectivelabs/sdk-ui-ts";
import {
  DEFAULT_PRICE_WARNING_DEVIATION,
  UI_DEFAULT_MAX_NUMBER_OF_ORDERS
} from "@/app/utils/constants";
import { TradeExecutionType, TradeField } from "@/types";
export function useSpotError({
  isBuy,
  market,
  formValues,
  executionPrice,
  notionalWithFees,
  quoteAvailableBalance
}) {
  const spotStore = useSpotStore();
  const tradingTypeMarket = computed(
    () => formValues.value[TradeField.TradingType] === TradeExecutionType.Market
  );
  const orderbookOrders = computed(
    () => isBuy.value ? spotStore.sells : spotStore.buys
  );
  const lastTradedPrice = computed(() => {
    if (spotStore.trades.length === 0 || !market.value) {
      return ZERO_IN_BASE;
    }
    const [trade] = spotStore.trades;
    return new BigNumberInBase(
      new BigNumberInBase(trade.price).toWei(
        market.value.baseToken.decimals - market.value.quoteToken.decimals
      )
    );
  });
  const highDeviation = computed(() => {
    const quantity = new BigNumberInBase(
      formValues.value[TradeField.BaseAmount] || 0
    );
    if (executionPrice.value.lte(0) || lastTradedPrice.value.lte(0) || quantity.lte(0)) {
      return false;
    }
    const deviationPrice = isBuy.value ? lastTradedPrice.value.div(executionPrice.value) : executionPrice.value.div(lastTradedPrice.value);
    const deviation = new BigNumberInBase(1).minus(deviationPrice).times(100);
    return deviation.gt(DEFAULT_PRICE_WARNING_DEVIATION);
  });
  const availableBalanceError = computed(() => {
    if (!notionalWithFees || !quoteAvailableBalance || !notionalWithFees.value || !quoteAvailableBalance.value) {
      return false;
    }
    return isBuy.value && quoteAvailableBalance.value.lt(notionalWithFees.value);
  });
  const subaccountOrders = computed(
    () => spotStore.subaccountOrders.filter((order) => {
      if (!market.value) {
        return false;
      }
      return order.orderSide === formValues.value[TradeField.OrderType] && order.marketId === market.value.marketId;
    })
  );
  const maxOrdersError = computed(() => {
    return !tradingTypeMarket.value && subaccountOrders.value && subaccountOrders.value.length >= UI_DEFAULT_MAX_NUMBER_OF_ORDERS;
  });
  const insufficientLiquidity = computed(() => {
    const quantity = new BigNumberInBase(
      formValues.value[TradeField.BaseAmount] || 0
    );
    if (quantity.lte(0) || !orderbookOrders.value || !market.value) {
      return false;
    }
    const { baseToken } = market.value;
    const totalFillableQuantity = orderbookOrders.value.reduce(
      (totalAmount, { quantity: quantity2 }) => {
        return totalAmount.plus(
          new BigNumberInWei(quantity2).toBase(baseToken.decimals)
        );
      },
      ZERO_IN_BASE
    );
    return quantity.gt(totalFillableQuantity);
  });
  return {
    highDeviation,
    maxOrdersError,
    insufficientLiquidity,
    availableBalanceError
  };
}
